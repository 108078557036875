











































































































































































import Vue from "vue";
import content from "@/content.json";
import BeheerTable from "@/components/lib/BeheerTable.vue";

export default Vue.extend({
  name: "VaststelComponent",
  components: { BeheerTable },
  data: () => ({
    page: content.pages.vaststel,
    links: content.links,
    succes: "",
    error: "",
    bestand_vaststellen: false,
    loading: false,
    marktaandeel: {
      marktaandeel_predicted: 0,
      marktaandeel_fixed: 0,
    },
    dialog: {
      FixMarktaandeel: false,
      FixFile: false,
    },
    euro_format: Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }),
    aanleverStatusData: [],
    aanleverRemaining: -1,
  }),
  methods: {
    commitFile() {
      this.$request
        .post(
          `${this.$store.state.APIurl}/document/vaststellen?document_id=${this.$store.state.user.document.id}`
        )
        .then(() => {
          const comittedDocument = this.$store.state.user.document;
          comittedDocument.is_vastgesteld = true;
          this.bestand_vaststellen = false;
          this.$store.dispatch("updateDocument", comittedDocument);
          this.$data.succes = "Uw bestand is nu succesvol vastgesteld.";
          this.$data.error = undefined;
        })
        .catch((error: any) => {
          this.$data.error = error;
        });
      this.dialog["FixFile"] = false;
    },
    commitMarktaandeel() {
      this.loading = true
      this.$request
        .post(
          `${this.$store.state.APIurl}/marktaandeel/fix?document_id=${this.$store.state.user.document.id}`
        )
        .then(() => {
          const comittedDocument = this.$store.state.user.document;
          comittedDocument.marktaandeel_vastgesteld = true;
          this.$store.dispatch("updateDocument", comittedDocument);
          this.$data.succes =
            "Het marktaandeel is nu succesvol vastgesteld. Indien uw bestand nog fouten bevat kunt u dit nog steeds verbeteren";
          this.$data.error = undefined;
          this.btnEnableAanleveringVaststellen();
        })
        .catch((error: any) => {
          console.log(error.response.data.detail);
          this.$data.error = error.response.data.detail;
        })
        .finally(() => {
          this.loading = false
          this.calculateMarktaandeel();
        });
      this.dialog["FixMarktaandeel"] = false;
    },
    calculateMarktaandeel() {
      this.$request
        .get(
          `${this.$store.state.APIurl}/marktaandeel/calculate?document_id=${this.$store.state.user.document.id}`
        )
        .then((response: any) => {
          this.marktaandeel = response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data.detail);
          this.$data.error = error.response.data.detail;
        });
    },
    btnEnableAanleveringVaststellen() {
      // rewrite this to use an array of conditions where all conditions must be true
      const conditions = [
        this.$store.state.user.document,
        !this.bestand_vastgesteld,
        this.marktaandeel_vastgesteld,
        this.$store.state.user.numFindings.critical === 0,
        this.$store.state.user.numFindings.high <= 20,
      ];
      this.bestand_vaststellen = conditions.every((condition) => condition);
    },
    fetchAanleverStatus() {
      this.$request(
        `${this.$store.state.APIurl}/document/aanlever-status`
      ).then((response: any) => {
        this.aanleverStatusData = response.data;
        let num = this.aanleverStatusData
          .map((a: any) => a.Geüpload)
          .filter(Boolean).length;
        this.aanleverRemaining = this.aanleverStatusData.length - num;
      });
    },
  },
  created() {
    this.btnEnableAanleveringVaststellen();
    this.calculateMarktaandeel();
  },
  computed: {
    marktaandeel_vastgesteld() {
      return this.$store.state.user.document.marktaandeel_vastgesteld != 0;
    },
    bestand_vastgesteld() {
      return this.$store.state.user.document.is_vastgesteld;
    },
  },
  mounted() {
    this.fetchAanleverStatus();
  },
});
